import {useState} from "react";
import {createNotification} from "app/actions/notifications";
import {create as createMessage} from "app/requests/message";
import useAppContext from "lib/hooks/use-app-context";
import {withoutBlankValues} from "lib/object";

export default function Message() {
  const [{user}, dispatch] = useAppContext();
  const [record, setRecord] = useState(user ? {
    email: user.email,
    name: [user.first_name, user.last_name].filter(Boolean).join(" ")
  } : {});
  const onChange = ({target: {name, value}}) => (
    setRecord({...record, [name]: value})
  );
  const onSubmit = (e) => {
    e.preventDefault();

    const updates = withoutBlankValues(record);

    if(Object.keys(updates).length === 0) {
      return dispatch(createNotification({
        content: "Please fill out the form before submitting",
        type: "danger"
      }));
    }

    createMessage({message: updates}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "primary" : "danger"
      }));

      if(data.success) { setRecord({}); }
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <input
          className="form-control"
          id="record-name"
          name="name"
          onChange={onChange}
          placeholder="Name"
          required={true}
          type="text"
          value={record.name || ""}
        />
      </div>
      <div className="form-group">
        <input
          className="form-control"
          id="record-email"
          name="email"
          onChange={onChange}
          placeholder="Email"
          required={true}
          type="email"
          value={record.email || ""}
        />
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          id="record-content"
          name="content"
          onChange={onChange}
          placeholder="Message"
          required={true}
          rows={3}
          value={record.content || ""}
        />
      </div>
      <div className="form-group">
        <button type="submit" className="btn btn-primary px-5">Send</button>
      </div>
    </form>
  );
}
