import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {get as getRecord} from "app/requests/admin/messages";
import Loading from "components/pages/loading";

export default function AdminMessagesShow() {
  const {id} = useParams();
  const [record, setRecord] = useState(null);

  useEffect(() => {
    getRecord(id).then((data) => setRecord(data.record));
  }, []);

  if(!record) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <h1 className="text-center">Message</h1>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-email">Email</label>
            </div>
            <input
              className="form-control"
              id="record-email"
              name="email"
              readOnly={true}
              type="email"
              value={record.email || "Blank"}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-name">Name</label>
            </div>
            <input
              className="form-control"
              id="record-name"
              name="name"
              readOnly={true}
              type="text"
              value={record.name || "Blank"}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="record-content">Content</label>
            </div>
            <textarea
              className="form-control"
              id="record-content"
              name="content"
              readOnly={true}
              value={record.content || "Blank"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
