import PropTypes from "prop-types";
import {useState} from "react";
import {createNotification} from "app/actions/notifications";
import {destroy as destroyRecord, preview, publish, unpublish} from "app/requests/admin/newsletters";
import {Publish as PublishModal} from "components/helpers/modal";
import useAppContext from "lib/hooks/use-app-context";
import {displayDate} from "lib/string";

function AdminNewslettersListRow({onDestroy, record: originalRecord}) {
  const dispatch = useAppContext()[1];
  const [record, setRecord] = useState(originalRecord);
  const [showPublish, setShowPublish] = useState(false);
  const onDelete = () => {
    if(!window.confirm("Are you sure you want to delete this newsletter?")) { return; }

    destroyRecord(record.uid).then(({message, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "danger"}));

      if(success) { onDestroy(record.uid); }
    });
  };
  const onPreview = () => {
    if(!window.confirm("Do you want to preview this record?")) { return; }

    preview(record.uid).then(({message, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "danger"}));
    });
  };
  const onPublish = (date) => {
    setShowPublish(false);

    publish(record.uid, {published_at: date}).then(({record: _record, message, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "danger"}));

      if(success) { setRecord(_record); }
    });
  };
  const onUnpublish = () => {
    setShowPublish(false);

    unpublish(record.uid).then(({record: _record, message, success}) => {
      dispatch(createNotification({content: message, type: success ? "success" : "danger"}));

      if(success) { setRecord(_record); }
    });
  };
  const published = record.published_at && (new Date(record.published_at) < new Date());

  return (
    <tr>
      <td>{record.published_at ? displayDate(record.published_at) : "None"}</td>
      <td>{record.subject}</td>
      <td>{displayDate(record.created_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <button type="button" className="btn btn-sm btn-warning" onClick={onPreview}>Preview</button>
          {published ? (
            <a className="btn btn-sm btn-primary" data-confirm="Newsletter is already published. Still want to edit?" href={`/admin/newsletters/${record.uid}`}>Edit</a>
          ) : (
            <>
              <button className="btn btn-sm btn-success" onClick={() => setShowPublish(true)} type="button">Publish</button>
              <a className="btn btn-sm btn-primary" href={`/admin/newsletters/${record.uid}`}>Edit</a>
            </>
          )}
          <button className="btn btn-sm btn-danger" onClick={onDelete} type="button">Destroy</button>
        </div>
        <PublishModal
          onClose={() => setShowPublish(false)}
          onPublish={onPublish}
          onUnpublish={onUnpublish}
          show={showPublish}
        />
      </td>
    </tr>
  );
}

AdminNewslettersListRow.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  record: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    published_at: PropTypes.string,
    subject: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired
  }).isRequired
};

export default AdminNewslettersListRow;
