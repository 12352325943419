import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {get as getRecord} from "app/requests/newsletters";
import {ActionText} from "components/helpers";
import {Loading} from "components/pages";
import {displayDateTime} from "lib/string";

export default function NewslettersShow() {
  const {uid} = useParams();
  const [record, setRecord] = useState(null);

  useEffect(() => {
    getRecord(uid).then((data) => setRecord(data.record));
  }, [uid]);

  if(!record) { return <Loading />; }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="text-center">
            <h1>{record.subject}</h1>
            <div className="mb-1 small text-center">
              Published on {displayDateTime(record.published_at) || "Unpublished"}
            </div>
          </div>
          <div className="custom-html">
            <ActionText.Content html={record.content} />
          </div>
        </div>
      </div>
    </div>
  );
}
