import PropTypes from "prop-types";
import ContentEditor from "components/helpers/form/content-editor";

function AdminNewslettersForm({onChange, onSubmit, value}) {
  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <div className="mb-3">
            <ContentEditor
              id="newsletter-content"
              label="Content"
              name="content"
              onChange={onChange}
              value={value("content")}
            />
          </div>
          <small className="form-text text-muted mb-3">
            Preview text usually appears in the list view of an email client
          </small>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="newsletter-preview-text">Preview Text</label>
            </div>
            <textarea
              className="form-control"
              id="newsletter-preview-text"
              name="preview_text"
              onChange={onChange}
              placeholder="Explore today's case on ultrasounding cat scratches"
              value={value("preview_text")}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="newsletter-subject">Subject</label>
            </div>
            <input
              className="form-control"
              id="newsletter-subject"
              name="subject"
              onChange={onChange}
              placeholder="US GEL - Ouchie"
              type="text"
              value={value("subject")}
            />
          </div>
          <small className="form-text text-muted mb-3">This will not yet publish your newsletter</small>
          <div className="form-group text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </form>
  );
}

AdminNewslettersForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminNewslettersForm;
