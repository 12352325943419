import PropTypes from "prop-types";
import {displayDate} from "lib/string";

function AdminMessagesListRow({record}) {
  return (
    <tr>
      <td>{record.email}</td>
      <td>{record.name}</td>
      <td>{displayDate(record.created_at)}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Actions">
          <a className="btn btn-sm btn-primary" href={`/admin/messages/${record.id}`}>View</a>
          {record.user && (
            <a className="btn btn-sm btn-secondary" href={`/admin/users/${record.user.id}`}>User</a>
          )}
        </div>
      </td>
    </tr>
  );
}

AdminMessagesListRow.propTypes = {
  record: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  }).isRequired
};

export default AdminMessagesListRow;
